import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { List, Icon, Button, Col, Row } from 'antd';
import { companyName, headerBase64PNG, headerBase64PDF, validSLSXExtension, validAccessExtensions, validPowerPointExtensions, validPublisherExtensions, validWordExtensions, headerBase64DOC, headerBase64DOCX, headerBase64PPT, headerBase64PPTX, headerBase64MDB, headerBase64ACCDB, headerBase64XLS, headerBase64XLSX, headerBase64PUB } from 'utils/constants';
import { getExtFile, setIconFile } from 'utils/';
import Loader from 'components/common/Loader';
import MyButton from 'components/common/MyButton';
import SEmpty from 'components/common/SEmpty';
import { useDispatch } from 'react-redux';
import { requestDownloadFilePreview } from 'redux/actions/downloadFile';
import { useSelector } from 'react-redux';
import useWasRendered from 'hooks/useWasRendered';
import { useTranslation } from 'react-i18next';

import { CheckMarkIcon, RejectedMark } from 'utils/icons';
import { setCapitalLetter } from 'utils/validations';

function ListFiles(props) {
  let {
    isFromCaseDetail,
    source,
    isLoading,
    type,
    pageSize,
    footer,
    downloadDocument,
    imageIsLoading,
    inModal,
    handleDelete,
    itCanDeleteFiles,
    canDownloadFiles,
    checkIfRenderDelete,
    renderExtraActions,
    keyFileId,
    downloadOptions,
    showIconOnText,
    isfromProofs
  } = props;
  const dispatch = useDispatch();
  const { wasRendered, setWasRendered } = useWasRendered();
  const downloadFile = useSelector(state => state.downloadFile);
  const [listTypeFiles, setAuxListType] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    let lastView = localStorage.getItem('viewTypeList');

    if (lastView) {
      setAuxListType(lastView);
    } else if (type === '') {
      setAuxListType('list');
    } else {
      setAuxListType(type);
    }
  }, [listTypeFiles, type, setWasRendered, wasRendered]);

  let defaultFooter = (
    <div data-test className="float-right">
      <b>{companyName}</b>
    </div>
  );

  pageSize = typeof pageSize !== 'undefined' ? pageSize : 4;
  source = typeof (source && source.data) !== 'undefined' ? source : {};
  footer = typeof footer !== 'undefined' ? footer : defaultFooter;
  inModal = typeof inModal !== 'undefined' ? inModal : false;

  const getHeaderBase64 = ext => {

    let headerBase64 = headerBase64PNG;
    switch (ext) {
      case 'pdf':
        headerBase64 = headerBase64PDF;
        break;
      case 'doc':
        headerBase64 = headerBase64DOC;
        break;
      case 'docx':
        headerBase64 = headerBase64DOCX;
        break;
      case 'ppt':
        headerBase64 = headerBase64PPT;
        break;
      case 'pptx':
        headerBase64 = headerBase64PPTX;
        break;
      case 'mdb':
        headerBase64 = headerBase64MDB;
        break;
      case 'accdb':
        headerBase64 = headerBase64ACCDB;
        break;
      case 'pub':
        headerBase64 = headerBase64PUB;
        break;
      case 'xls':
        headerBase64 = headerBase64XLS;
        break;
      case 'xlsx':
        headerBase64 = headerBase64XLSX;
        break;
      default:
        break;
    }
    return headerBase64;

  };

  const handleOpenAction = (extFile, itemFileId, comments, preview, name) => {
    let setHeaderBase64 = getHeaderBase64(extFile);
    if (downloadOptions) {
      let urlParams;
      if (downloadOptions.order === 'downloadCaseFile') {
        const { extraParams } = downloadOptions;
        const { caseId, base64 } = extraParams;
        urlParams = `${caseId}/${itemFileId}/${base64}`;
      } else {
        urlParams = { ...downloadOptions.extraParams, fileId: itemFileId };
        urlParams = Object.values(urlParams).join('/');
      }

      dispatch(
        requestDownloadFilePreview({
          params: urlParams,
          fileId: itemFileId,
          headerBase64: setHeaderBase64,
          serviceToDownload: downloadOptions.serviceToDownload,
          onDownloadFail: downloadOptions.onDownloadFail,
          onDownloadSuccess: (e) => downloadOptions.onDownloadSuccess({...e, preview})
        })
      );
    } else {
      downloadDocument(itemFileId, setHeaderBase64, comments, extFile, preview, name);
    }
  };

  const getIcon = status => {
    let icon = null;
    switch (status) {
      case 2: //Aprobado
        icon = (
          <Button style={{ cursor: 'default' }} shape="circle">
            <CheckMarkIcon />
          </Button>
        );
        break;

      case 3: //Rechazado
        icon = (
          <Button type="danger" style={{ cursor: 'default' }} shape="circle">
            <RejectedMark />
          </Button>
        );
        break;

      case 4: // Favorito
        icon = (
          <Button shape="circle">
            <Icon type="heart" />
          </Button>
        );
        break;

      default:
        break;
    }
    return icon;
  };

  const addElementsActions = item => {
    let ext = getExtFile(item.name);
    let comments = item.rejectMessage;
    let actions = [];

    if (renderExtraActions) {
      const extraActions = renderExtraActions(item);
      actions = [...actions, ...extraActions];
    }
    if (canDownloadFiles) {
      actions = [
        ...actions,
        <MyButton
          jest-id="DownloadFile"
          key="download"
          onClick={() => handleOpenAction(ext, item[keyFileId], comments, false, item.name)}
          shape="circle"
          loading={
            downloadFile && downloadFile[item[keyFileId]]
              ? downloadFile[item[keyFileId]].isLoading
              : false
          }
          type="link"
          title={t('buyerdeliveryinfo.downloadfiles')}
          icon="download"
        />
      ];
    }

    if (itCanDeleteFiles && checkIfRenderDelete(item)) {
      actions = [
        ...actions,
        <MyButton
          jest-id="DeleteFile"
          key="delete"
          onClick={() => handleDelete(item)}
          shape="circle"
          type="link"
          icon="delete"
        />
      ];
    }

    return actions;
    //add another option , for examples itCanDownload, itCanPreview
  };

  const textLinkToActionFile = item => {
    let ext = getExtFile(item.name);
    let elementToDisplay = '';
    let comments = item.rejectMessage;
    const previewNotAllowedExt = [...validSLSXExtension, ...validAccessExtensions, ...validPowerPointExtensions, ...validPublisherExtensions, ...validWordExtensions]
    let propertyLoading =
      item[keyFileId] && imageIsLoading === true ? { loading: imageIsLoading } : '';
    if (downloadFile && downloadFile[item[keyFileId]]) {
      propertyLoading =
        downloadFile && downloadFile[item[keyFileId]]
          ? { loading: downloadFile[item[keyFileId]].isLoading }
          : false;
    }

    if (!previewNotAllowedExt.includes(ext) ) {
      elementToDisplay = (
        <Button
          {...propertyLoading}
          type="link"
          onClick={e => {
            e.preventDefault();
            handleOpenAction(ext, item[keyFileId], comments, true, item.name, item.content);
          }}
          href={item[keyFileId]}
          title={item.name}
        >
          {item.name}
        </Button>
      );
    } else {
      elementToDisplay = (
        <Button
          {...propertyLoading}
          type="link"
          onClick={e => {
            e.preventDefault();
          }}
          style={{ cursor: 'not-allowed' }}
          href={item[keyFileId]}
          title={item.name}
        >
          {item.name}
        </Button>
      );
    }
    return elementToDisplay;
  };

  const renderItem = item => {
    // if(item && item.name && item.content){ CHECK ON DEV ERROR DOESNT SHOW
    if((item && item.name && !isfromProofs) || (item && item.name && item.content && isfromProofs)){
      item.name = setCapitalLetter(item.name.toLowerCase());
    let ext = getExtFile(item.name);
    let iconName = setIconFile(ext);
    let comments = item.rejectMessage;

    if (listTypeFiles === 'card') {
      return (
        <List.Item key={item[keyFileId]} actions={addElementsActions(item)}>
          <List.Item.Meta
            avatar={<Icon type={iconName} style={{ fontSize: '44px' }} />}
            title={
              <>
                {textLinkToActionFile(item)}
                {showIconOnText ? getIcon(item.statusId) : null}
                {isFromCaseDetail && <Row style={{ padding: '20px' }}>{comments} </Row>}
              </>
            }
          />
        </List.Item>
      );
    } else {
      return (
        <List.Item
          data-test="listItemComponent"
          className={`listItemComponent ${inModal ? 'text-left' : ''} `}
          actions={addElementsActions(item)}
        >
          <>
            {textLinkToActionFile(item)}
            {showIconOnText ? getIcon(item.statusId) : null}
          </>
          {isFromCaseDetail && <row style={{ padding: '10px' }}>{comments}</row>}
        </List.Item>
      );
    }
    }else{
      return <></>;
    }
  };

  const setViewListType = useCallback(typeList => {
    localStorage.setItem('viewTypeList', typeList);
    setAuxListType(typeList);
  }, []);

  return (
    <React.Fragment>
      {(source.isLoading || isLoading) && <Loader data-test="loaderComponent" size={48} />}

      {!source.isLoading && (
        <React.Fragment>
          {source.data && source.data.length > 0 && (
            <>
              <Col
                data-test="colComponent"
                span={20}
                className="text-right float-right"
                style={{ zIndex: '1' }}
              >
                <Button
                  jest-id="ChangeToList"
                  data-test="buttonComponent"
                  id="btn-ul"
                  className="button-change-view-list"
                  type="dashed"
                  icon="unordered-list"
                  onClick={() => setViewListType('list')}
                />
                <Button
                  jest-id="ChangeToCard"
                  data-test="buttonComponent"
                  id="btn-apps"
                  className="button-change-view-list"
                  type="dashed"
                  icon="appstore"
                  onClick={() => setViewListType('card')}
                />
              </Col>
              <List
                jest-id="ListPDF"
                data-test="listComponent"
                itemLayout={listTypeFiles === 'list' ? 'horizontal' : 'vertical'}
                size="large"
                grid={{
                  gutter: 0,
                  column: listTypeFiles === 'list' || (listTypeFiles === 'card' && inModal) ? 1 : 4
                }}
                pagination={{
                  pageSize
                }}
                className={`list-files ${inModal ? 'list-inline-flex' : ''}`}
                dataSource={source.data}
                footer={footer}
                renderItem={renderItem}
              />
            </>
          )}

          {source.data && source.data.length === 0 && (
            <SEmpty
              type="files"
              description={[
                <strong
                  key="not-results"
                  style={{ marginLeft: '15px' }}
                >{t('system.filesnotfound')}</strong>
              ]}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

ListFiles.propTypes = {
  isFromCaseDetail: PropTypes.bool,
  source: PropTypes.object,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
  pageSize: PropTypes.number,
  footer: PropTypes.instanceOf(Element),
  downloadDocument: PropTypes.func,
  imageIsLoading: PropTypes.bool,
  currentFileId: PropTypes.string,
  inModal: PropTypes.bool,
  handleDelete: PropTypes.func,
  canDownloadFiles: PropTypes.bool,
  checkIfRenderDelete: PropTypes.func,
  renderExtraActions: PropTypes.func,
  keyFileId: PropTypes.string,
  showIconOnText: PropTypes.bool,
  isfromProofs: PropTypes.bool
};

ListFiles.defaultProps = {
  isFromCaseDetail: false,
  inModal: null,
  source: {
    isLoading: null
  },
  type: 'list',
  pageSize: 0,
  canDownloadFiles: true,
  checkIfRenderDelete: () => true,
  renderExtraActions: undefined,
  keyFileId: 'fileId',
  showIconOnText: true,
  isfromProofs: false
};

export default ListFiles;
