import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Layout, Typography } from 'antd';
import { useTranslation } from "react-i18next";

import TermsAndConditions from '../common/TermsAndConditions';
import MyModal from '../common/MyModal';
import logo from 'assets/img/logov2.svg';
import 'assets/css/public.css';

export default function PublicLayout({ children }) {
  const [showModal, setShowModal] = useState(false);
  const { Title } = Typography;
  const _handleShowModal = () => {
    const current = showModal;
    setShowModal(!current);
  };

  const { i18n } = useTranslation()
  return (

    <Layout>
      <Row className="max-height" type="flex" justify="center" align="middle">
        <Col
          id="left-column"
          className="text-center"
          xs={{ span: 24, offset: 0 }}
          md={{ span: 12 }}
          xl={{ span: 12 }}
        >
          <div id="container-logo-login">
            <h1 style={{ fontSize: '60px', color: '#fff', fontWeight: '500' }}>
              <img
                rel="preload"
                as="image"
                alt="logoLogin"
                src={logo}
                loading="lazy"
                width="600px"
              />
            </h1>
          </div>
        </Col>
        <Col
          id="right-column"
          className="max-height"
          xs={{ span: 22, offset: 0 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
        >
          {React.cloneElement(children, { _handleShowModal: _handleShowModal })}
          <div id='version-label'>
            <p>Version {process.env.REACT_APP_VERSION} {i18n.language}</p>
          </div>

        </Col>
      </Row>

      {showModal && (
        <Suspense>
          <MyModal
            jest-id="ShowTerms"
            title={
              <Title className="modal-title" level={3}>
                Términos y Condiciones
              </Title>
            }
            footer={null}
            handleShow={_handleShowModal}
            show={showModal}
            component={TermsAndConditions}
          />
        </Suspense>
      )}
    </Layout>
  );
}

PublicLayout.propTypes = {
  children: PropTypes.element.isRequired
};

PublicLayout.defaultProps = {
  children: <div />
};
