import React, { useState } from 'react';
import { Layout, Typography } from 'antd';
import TopBar from '../../components/common/TopBar';
import SideNavBar from '../../components/common/SideNavBar';
import PropTypes from 'prop-types';
import '../../assets/css/private.css';
import MyModal from '../common/MyModal';
import TermsAndConditions from '../common/TermsAndConditions';

export default function PublicRegister({ children, externalRegister }) {
  const { Header, Content, Sider } = Layout;
  const { Title } = Typography;

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => {
    const current = showModal;
    setShowModal(!current);
  };
  return (
    <Layout>
      <TopBar externalRegister={externalRegister} Header={Header} />
      <Layout>
        <SideNavBar Sider={Sider} externalRegister={externalRegister} />
        <Layout
          style={{
            padding: '64px 15px 15px 10px',
            marginLeft: 60
          }}
        >
          <Content
            style={{
              background: '#fff',
              padding: '15px 10px',
              margin: '10px',
              minHeight: 300,
              textAlign: 'center'
            }}
          >
            {React.cloneElement(children, {
              handleShowModal: handleShowModal
            })}
          </Content>
        </Layout>
      </Layout>
      <MyModal
        title={
          <Title className="modal-title" level={3}>
            Términos y condiciones
          </Title>
        }
        handleShow={handleShowModal}
        show={showModal}
        component={TermsAndConditions}
        footer={null}
      />
    </Layout>
  );
}

PublicRegister.propTypes = {
  children: PropTypes.element.isRequired
};

PublicRegister.defaultProps = {
  children: <div />
};
