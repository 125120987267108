import { Modal } from 'antd';
import ListFiles from 'components/ListFiles';
import SButton from 'components/common/SButton';
import SDocumentAddressTypeID from 'components/common/SDocumentAddressTypeID';
import SToggleModalControl from 'components/common/SToggleModalControl';
import UploadInput from 'components/common/UploadInput';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { headerBase64PDF, validImageFiles, validPDFFiles } from 'utils/constants';
import { proofGetRequest, proofDeleteRequest, proofAddRequest, proofTypeChangeRequest, proofGetSuccess } from '../../redux/actions/usersActions';
import moment from 'moment';
import { getExtFile } from 'utils';
import MyModal from 'components/common/MyModal';
import STitle from 'components/common/STitle';
import PreviewPDF from 'components/common/PreviewPDF';
import { buttonsProps } from 'helpers/helperModals';
import { sendNotification } from 'helpers/helperNotifications';

const UserProofs = (props) => {

  const {
    readOnly,
    userId,
    form,
    locallyProofs,
    setLocallyProofs,
    proofGetRequest,
    proofAddress,
    addressID,
    proofDeleteRequest,
    proofAddRequest,
    proofTypeChangeRequest,
    isMoral,
    proofGetSuccess
  } = props;
  const [selectedAddressTypeId, setSetSelectedAddressTypeId] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [showModalDocument, setShowModalDocument] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const values = form.getFieldsValue();
    if(isMoral){
      if (values.addressDocumentTypeId) {
        setSetSelectedAddressTypeId(values.addressDocumentTypeId);
      }
    }else{
      if (values.documentTypeId) {
        setSetSelectedAddressTypeId(values.documentTypeId);
      }
    }
  }, [form]);

  useEffect(() => {
    if (userId && proofAddress?.data?.length > 0) {
      setSetSelectedAddressTypeId(proofAddress.data[0].documentTypeId);
      if(proofAddress.data[0].content == null){
        proofGetSuccess([]);
      }
    }
  }, [proofAddress])

  const handleUploadFile = objFile => {
    //validate if objFile is an array or an object
    if (Array.isArray(objFile)) {
      const objToSet = objFile.map(file => {
        return {
          ...file,
          name: getFileName(file.name),
          fileName: getFileName(file.name),
          documentTypeId: selectedAddressTypeId
        };
      });
      if(validateMaxFiles(objToSet.length, 15)){
        if (userId) {
            objToSet.forEach(file => {
              proofAddRequest({file, userId});
            });
        } else {
          setLocallyProofs([...locallyProofs, ...objToSet]);
        }
      }else{
        sendNotification('info', t('upload.validationmax'));
      }
    } else {
      if(validateMaxFiles(1, 15)){
        if (userId) {
          proofAddRequest({file: { ...objFile,  fileName: getFileName(objFile.name), name: getFileName(objFile.name), documentTypeId: selectedAddressTypeId }, userId});
        } else {
          setLocallyProofs([...locallyProofs, { ...objFile,  fileName: getFileName(objFile.name), name: getFileName(objFile.name), documentTypeId: selectedAddressTypeId }]);
        }
      }else{
        sendNotification('info', t('upload.validationmax'));
      }
    }
  };

  const getDocuments = () => {
    if (userId) {
      proofGetRequest({userId})
    }
  };

  const getFileName = (fileName) => {
    return `${t('moralbuyers.step2.proofaddress')}${moment().format('YYMMDDmmss')}${Math.floor(Math.random() * 90) + 10}.${getExtFile(fileName)}`;
  }

  const renderFooter = () => {
    if (!readOnly) {
      return (
        <UploadInput
          type="primary"
          shape={null}
          allowSize={15}
          formatsText="PDF, JPG o PNG"
          getFileBase64={handleUploadFile}
          validTypesFile={[...validPDFFiles, ...validImageFiles]}
          customSizeMessage={t('MSG_005')}
          isProof={true}
          disabled={selectedAddressTypeId === null}
        />
      );
    } else {
      return null;
    }
  };

  const actionsOnOpen = () => {
    getDocuments();
    if (userId && proofAddress?.data?.length > 0) {
      setSetSelectedAddressTypeId(proofAddress.data[0].documentTypeId);
    } else if (!userId && locallyProofs?.length > 0) {
      setSetSelectedAddressTypeId(locallyProofs[0].documentTypeId);
    }
  };

  const actionsOnCancel = () => {
    setSetSelectedAddressTypeId(null)
  };

  const handleDownloadDocument = (itemFileId, setHeaderBase64, comments, extFile, preview, name) =>{
    setFilePreview({
      itemFileId,
      setHeaderBase64,
      comments,
      extFile,
      preview,
      name,
      content : userId ? proofAddress.data.find(x => x.name == name)?.content : locallyProofs.find(x => x.name == name)?.content
    });
    setShowModalDocument(true);
  }

  const handleDelete = (file) =>{
    const loadedProofs = userId ? proofAddress?.data ?? [] : locallyProofs;
    if(loadedProofs.length > 1){
      Modal.confirm({
        title: (
          <STitle
            titleText={t('system.file.confimationdelete')}
            className="modal-title"
            level={4}
          />
        ),
        okText: t('system.yes'),
        cancelText: t('system.no'),
        okButtonProps: buttonsProps,
        cancelButtonProps: buttonsProps,
        onOk() {
          if (userId) {
            proofDeleteRequest({fileId : file.fileId })
          } else {
            setLocallyProofs(locallyProofs.filter(x => x.name !== file.name));
          }
        }
      });
    }else{
      sendNotification('info', t('min.proof.address'));
    }
   
  }

  //validate max 15 files between locally and server
  const validateMaxFiles = (newFiles, max) => {
    if(userId){
      return (proofAddress?.data?.length ?? 0) + newFiles <= max;
    }else{
      return (locallyProofs?.length ?? 0) + newFiles <= max;
    }
  }

  const handleProofType = (value) => {
    if(userId && proofAddress?.data?.length > 0){
      proofTypeChangeRequest({userId, documentTypeId: value});
    }
  };

  return (
    <>
     <SToggleModalControl
      type='primary'
      shape={null}
      iconButton='upload'
      labelButton={readOnly ? 'Ver comprobante' :'Cargar comprobante de domicilio'}
      onOpen={actionsOnOpen}
      onCancel={actionsOnCancel}
    >
      <Modal
        width={"50%"}
        title={'Comprobantes de domicilio'}
        footer={renderFooter()}
      >
        <SDocumentAddressTypeID
          jest-id="documents"
          form={form}
          disabled={readOnly || proofAddress?.isLoading}
          initialValue={selectedAddressTypeId}
          value={selectedAddressTypeId}
          // {...isLoadingAddressID}
          addressID={addressID}
          style={{ width: '100%' }}
          onChange={handleProofType}
          isMoral={isMoral}
        />

        <ListFiles
          jest-id="ListFiles"
          type="list"
          downloadDocument={handleDownloadDocument}
          handleDelete={handleDelete}
          inModal={true}
          pageSize={5}
          source={{ isLoading: proofAddress?.isLoading , data: userId ? proofAddress?.data ?? [] : locallyProofs }}
          canDownloadFiles={false}
          itCanDeleteFiles={!readOnly}
          isfromProofs={true}
        />

      </Modal>

    </SToggleModalControl>
    <MyModal
      handleShow={() => setShowModalDocument(false)}
      show={showModalDocument}
      footer={null}
      title={<STitle titleText={t('moralbuyers.step2.proofaddress')} level={4} />}
      component={() => (
        <PreviewPDF
          titleDocument={filePreview && filePreview.name ? filePreview.name : ''}
          base64={filePreview && filePreview.content ? filePreview.content : null}
          type={filePreview.setHeaderBase64}
        />
      )}
      />
    </>
  )
}

function mapStateToProps(state) {
  return {
    
  };
}

export default connect(null, {
  proofGetRequest,
  proofDeleteRequest,
  proofAddRequest,
  proofTypeChangeRequest,
  proofGetSuccess
})(UserProofs);
