import React, { useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Icon, Input, Button, Checkbox, Col, Modal } from 'antd';
import { setRuleRequired, setRuleEmail, setLowerCase, setRuleMax } from '../../utils/validations';
import { connect, useDispatch } from 'react-redux';
import { requestLogin, resetPasswordRequest, forceLogout } from '../../redux/actions/authActions';
import './loginForm.css';
import SToggleModalControl from 'components/common/SToggleModalControl';
import TermsAndConditions from 'components/common/TermsAndConditions';
import STitle from 'components/common/STitle';
import SessionKiller from 'components/SessionKiller';
import RecoveryCreator from '../RecoveryForm/RecoveryForm';
import { sendNotification } from 'helpers/helperNotifications';
import { encryptAes } from '../../utils/aesEncoder';
import { useTranslation } from "react-i18next";


export const handleModal = (setShowModal, setErrorMessage, type, ref) => {
  setShowModal(type === 'cancel' ? false : true);
  setErrorMessage(null);
  if (ref) {
    ref.current.resetFields();
  }
};

export const handleModalSession = (setShowModal, setErrorMessage, type, ref) => {
  setShowModal(type === 'cancel' ? false : true);
  setErrorMessage(null);
  if (ref) {
    ref.current.resetFields();
  }
};

export function LoginForm(props) {
  const { isSubmitting, form, resetPasswordRequest: resetPasswordReq } = props;
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const ref = useRef();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModalSession, setShowModalSession] = useState(false);

  const { t } = useTranslation();

  const handleValidSession = useCallback(
    message => {
      if (message) {
        console.log(message);
        if (message.status && message.status == 400 && (message.data?.message?.includes('sesión activa') || message.data?.message?.includes('sessão ativa') || message.data?.message?.includes('active session'))) {
          setShowModalSession(true);
        }
      }
    },
    []
  );

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        let credentials = {
          username: values.email,
          password: encryptAes(values.password)
        };
        try {
          dispatch(requestLogin({ credentials, method: 'POST', handleValidSession }))
        } catch (error) {
          console.error(error);
        }
      }
    });
    /*setTimeout(() => {
      console.log(showModalSession)
      handleModalSession(setShowModalSession, setErrorMessage, 'open', false);
    }, 1500)*/
  };

  const handleSubmitAct = e => {
    props.form.validateFields(async (err, values) => {
      if (!err) {
        let credentials = {
          username: values.email,
          password: encryptAes(values.password)
        };
        let username = values.email

        try {

          dispatch(forceLogout({ username, method: 'POST' }))
          setTimeout(() => {
            dispatch(requestLogin({ credentials, method: 'POST' }))
          }, 1500)

        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const MSG_017 = (
    <span>
      {t('loginform.acepttermconditions')}
    </span>
  );

  const handleOnOK = e => {
    const { current } = ref;
    const { validateFields, resetFields } = current;
    validateFields(async (err, values) => {
      const data = {
        username: values.email
      };
      if (!err) {
        setLoading(true);
        const onSuccess = message => {
          sendNotification('success', message);
          setShowModal(false);
          resetFields();

          setLoading(false);
        };
        const onError = resError => {
          setErrorMessage(resError);
          setLoading(false);
        };
        resetPasswordReq({ dataRequest: data, onSuccess: onSuccess, onError: onError });
      }
    });
  };


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const SessionActive = () => {
    handleModal(setShowModal, setErrorMessage, 'open', false);

  }



  return (

    <>
      <Form
        noValidate
        onSubmit={handleSubmit}
        id="login-form"
        xs={24}
        sm={24}
        md={24}
        lg={20}
        xl={18}
      >
        <SessionKiller />

        <STitle level={2} titleText={t('loginform.header')} />
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [setRuleEmail(t('loginform.user')), setRuleRequired(t('loginform.user')), setRuleMax(80)],
            normalize: value => setLowerCase(value)
          })(
            <Input
              jest-id="login_email"
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.35)' }} />}
              placeholder={t('loginform.user')}
              size="large"
              maxLength={80}
              autoComplete="none"
              type="email"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [
              setRuleRequired(t('loginform.password')),
              { min: 8, message: t('loginform.validationpasswordmin') },
              { max: 16, message: t('loginform.validationpasswordmax') }
            ]
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.35)' }} />}
              type="password"
              maxLength={16}
              minLength={8}
              placeholder={t('loginform.password')}
              size="large"
              autoComplete="none"
              allowClear
            />
          )}
        </Form.Item>
        <Form.Item>
          <Col sm={24} md={14} className="text-left">
            {getFieldDecorator('acceptTerms', {
              valuePropName: 'checked',
              initialValue: false,
              rules: [
                {
                  required: true,
                  transform: value => value || undefined,
                  type: 'boolean',
                  message: MSG_017
                }
              ]
            })(<Checkbox>{t('loginform.acept')}</Checkbox>)}
            <SToggleModalControl type="link" iconButton="" labelButton={t('loginform.termscondition')}>
              <Modal
                title={
                  <STitle titleText={t('loginform.termscondition')} className="modal-title" level={3} />
                }
                width={600}
                destroyOnClose
                maskClosable={false}
                footer={null}
              >
                <div style={{ marginTop: 40 }}>
                  <TermsAndConditions />
                </div>
              </Modal>
            </SToggleModalControl>
          </Col>
          <Col sm={24} md={10} className="text-right">
            <SToggleModalControl
              type="link"
              className="login-form-forgot"
              labelButton={t('loginform.forwotpassword')}
              iconButton=""
            >
              <Modal
                jest-id="resetPassword"
                title={t('recovery.header')}
                type="choice"
                visible={showModal}
                okButtonProps={{
                  onClick: () => {
                    handleOnOK()
                  }
                }}
                onCancel={handleClose}
                closable={true}
                cancelButtonProps={handleClose}
                width={520}
                destroyOnClose
                okText={t('system.acept')}
                cancelText={t('system.cancel')}
              >
                <RecoveryCreator ref={ref} errorMessage={errorMessage} showModal={showModal} />
              </Modal>
            </SToggleModalControl>
          </Col>
        </Form.Item>
        <Form.Item>
          <Col lg={{ span: 7, offset: 18 }} sm={{ span: 4, offset: 20 }}>
            <Button
              jest-id="handleSubmit"
              shape="round"
              size="large"
              onClick={handleSubmit}
              id="btnLogin"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              className="login-form-button"
            >
              {isSubmitting ? t('loginform.wait') : t('loginform.access')}
            </Button>


          </Col>
        </Form.Item>
      </Form>


      <Modal
        jest-id="sesionActiva"
        title={t('loginform.sessionactive')}
        type="choice"
        visible={showModalSession}
        onOk={handleSubmitAct}
        onCancel={() => {
          setShowModalSession(false)
        }}
        closable={true}
        okButtonProps={{ loading: loading }}
        cancelButtonProps={{
          onClick: () => {
            setShowModalSession(false)
          }
        }}
        width={520}
        okText={t('system.acept')}
        cancelText={t('system.cancel')}
      >
        <div>
          <p>
            {t('loginform.sessionactivetext')}
          </p>
        </div>
      </Modal>
    </>
  );
}

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm);

function mapDispatchToProps(state) {
  return {
    isSubmitting: state.auth.isSubmitting
  };
}

export default withRouter(connect(mapDispatchToProps, { resetPasswordRequest })(WrappedLoginForm));
