/* eslint-disable no-console */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { requestLogOut, refreshToken, validSession } from 'redux/actions/authActions';
import { currentTimeInMiliseconds } from 'utils';

const ExpiredSession = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(true);
  const signoutTime = useRef(900000);
  const warningTime = useRef(890000);
  const refreshTokenTime = useRef(0);
  const countRefreshed = useRef(0);
  const countTimes = useRef(0);
  const blockRefresh = useRef(false);

  let warnTimeout = useRef();
  let logoutTimeout = useRef();
  let refreshTimeOut = useRef();
  const timeSessionValidLimit = process.env.REACT_APP_TIME_MIN_VALIDSESSION;

  const warn = () => {
    console.log('Sé cerrará la sesión');
  };
  const logout = useCallback(() => {
    if (localStorage.tkn && localStorage.user) {
      dispatch(requestLogOut());
    }
  }, [dispatch]);

  const handleBlock = useCallback(
    message => {
      console.log('message :>> ', message);
      setRefresh(!refresh);
      blockRefresh.current = false;
      if (refreshTimeOut.current) clearTimeout(refreshTokenTime.current);
      if (message !== 'no response') {
        console.log('countTokenRefreshed :>> ', countRefreshed.current);
      }
    },
    [refresh]
  );

  const handleValidSession = useCallback(message => {
    if (message) {
      dispatch(requestLogOut());
    }
  }, []);

  const refreshTkn = useCallback(() => {
    countTimes.current = countTimes.current + 1;
    if (!blockRefresh.current) {
      if (localStorage.tkn && localStorage.user) {
        const user = JSON.parse(localStorage.user);
        const expiresAt = user.expiresAt;
        let current = currentTimeInMiliseconds();
        if (current < expiresAt - 60000) {
          blockRefresh.current = true;
          if (localStorage.tkn) {
            countRefreshed.current = countRefreshed.current + 1;
            dispatch(refreshToken({ token: localStorage.tkn, method: 'PUT', handleBlock }));
          }
        } else {
          let rest = expiresAt - current - 50000;
          setTimeout(logout, rest < 0 ? 0 : rest);
        }
      }
    }
  }, [dispatch, handleBlock, logout]);

  const setTimeouts = useCallback(() => {
    warnTimeout.current = setTimeout(warn, warningTime.current);
    logoutTimeout.current = setTimeout(logout, signoutTime.current);
  }, [logout]);

  const setTimeOutRefresh = useCallback(() => {
    refreshTimeOut.current = setTimeout(refreshTkn, refreshTokenTime.current);
  }, [refreshTkn]);

  const validSessionTime = useCallback(async () => {
    dispatch(
      validSession({ sessionId: localStorage.tokenPic, method: 'POST', handleValidSession })
    );
  }, []);

  const clearTimeouts = useCallback(() => {
    if (warnTimeout.current) clearTimeout(warnTimeout.current);
    if (logoutTimeout.current) clearTimeout(logoutTimeout.current);
  }, [warnTimeout, logoutTimeout]);

  useEffect(() => {
    const limit = JSON.parse(localStorage.user).expiresAt - 180000 - new Date().getTime();
    const timerId = setTimeout(
      () => {
        setTimeOutRefresh();
      },
      limit < 0 ? 0 : limit
    );
    return () => {
      clearTimeout(timerId);
    };
  }, [setTimeOutRefresh, refresh]);

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
    if (navigator.onLine) {
      console.log('Web app online');
    } else console.log('Web app offline');
    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [clearTimeouts, setTimeouts]);

  useEffect(() => {
    const interval = setInterval(() => {
      validSessionTime();
    }, timeSessionValidLimit);
    return () => clearTimeout(interval);
  }, []);

  return <div />;
};

export default ExpiredSession;
