import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Menu, Icon } from 'antd';
import SideNavBar from 'components/common/SideNavBar';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

function ContainerSideNavBar({ children, toggleSide, drawerIsVisible }) {
  const [collapsed, setCollapsed] = useState(true);
  const [openKeys, setOpenKeys] = useState([]);
  const rootSubmenuKeys = [
    '200',
    '210',
    '300',
    '400',
    '500',
    '700',
    '900',
    '1000',
    '1100',
    '1200',
    '1300'
  ];
  useEffect(() => {
    toggleSide({ isCollapsed: true });
  }, [toggleSide]);
  const onOpenChange = useCallback(
    sOpenKeys => {
      const latestOpenKey = sOpenKeys.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(sOpenKeys);
      } else {
        if (latestOpenKey === '210') {
          setOpenKeys(['200', '210']);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
      }
    },
    [openKeys, rootSubmenuKeys]
  );

  const handleCollapse = useCallback(
    contextCollapsed => {
      setOpenKeys([]);
      setCollapsed(contextCollapsed);
      toggleSide({ isCollapsed: contextCollapsed });
    },
    [toggleSide]
  );
  return (
    <Layout>
      <SideNavBar
        jest-id="SideNavBar"
        data-test="side-nav-bar"
        Sider={Sider}
        SubMenu={SubMenu}
        Menu={Menu}
        Icon={Icon}
        onCollapse={handleCollapse}
        onChange={k => onOpenChange(k)}
        openKeys={openKeys}
        collapsed={collapsed}
      />
      <Layout
        style={{
          padding: '70px 10px 10px 10px',
          marginLeft: collapsed ? 80 : 200
        }}
      >
        <Content
          style={{
            padding: '0px 10px',
            margin: 0,
            minHeight: 280,
            textAlign: 'center',
            marginLeft: drawerIsVisible ? 300 : 0
          }}
          id="main-container"
        >
          {/* TODO  APPLY THIS LAYOUT TO REDUCE SPACE ON BIG SCREENS AND REFACTOR BUTTON  THAT TRIGGER OPEN SEARCH FORM TO WORK PERFECTLY TOGETHER  */}
          {/* <Row type="flex" justify="center">
        <Col sm={24} md={24} lg={22} xl={18}>
          {children}
        </Col>
      </Row> */}

          {/* <Suspense fallback={<Loader isPageLoader={true} />}> */}
          {children}
          {/* </Suspense> */}
        </Content>
      </Layout>
      <div id="float-search-form" />
    </Layout>
  );
}

export default ContainerSideNavBar;
