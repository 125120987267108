import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Layout, Modal, Input } from 'antd';
import TopBar from '../../components/common/TopBar';
import PropTypes from 'prop-types';
import '../../assets/css/private.css';
import ExpiredSession from '../ExpiredSession';
import { buttonsProps } from 'helpers/helperModals';
import { forceLogout } from 'redux/actions/authActions';
import { connect } from 'react-redux';
import { toggleSideBar } from 'redux/actions/userActions';
import { ChangePasswordForm } from 'components/myProfile';
import { withRouter } from 'react-router-dom';
import { changePasswordRequest } from 'redux/actions/userActions';
import { sendNotification } from 'helpers/helperNotifications';
import { useDispatch } from 'react-redux';
import { requestLogOut } from 'redux/actions/authActions';

import ContainerSideNavBar from './ContainerSideNavBar';
import { encryptAes } from '../../utils/aesEncoder';
import { useTranslation } from 'react-i18next';

function PrivateLayout(props) {
  const {
    children,
    toggleSideBar: toggleSide,
    forceLogout: forceOut,
    password,
    history,
    changePasswordRequest: changePasswordReq,
    drawerIsVis,
    userRedux
  } = props;
  const dataUser = JSON.parse(localStorage.user);
  const token = localStorage.tkn; // get token value from localStorage
  const dispatch = useDispatch();

  const { temporary, timelife } = dataUser ? dataUser : { temporary: false, timelife: false };
  const kill = useRef();

  const ref = useRef();
  const [showModal, setShowModal] = useState(true); // true
  const [newDate, setNewDate] = useState('');
  const { t } = useTranslation();

  const openModalForce = useCallback(
    e => {
      const { ctrlKey, shiftKey, keyCode } = e;

      if (ctrlKey && shiftKey && keyCode === 11) {
        Modal.confirm({
          title: '😈 Kill it! ☠️',

          content: <Input ref={kill} allowClear />,
          okButtonProps: buttonsProps,
          cancelButtonProps: buttonsProps,
          closable: true,
          onOk() {
            let user = kill.current.state.value;
            if (user) forceOut({ username: user });
          },
          onCancel() {}
        });
      }
    },
    [forceOut]
  );

  useEffect(() => {
    window.addEventListener('keypress', openModalForce);

    return () => {
      window.removeEventListener('keypress', openModalForce);
    };
  }, [openModalForce]);

  const [equalPass, setEqualPass] = useState(false);

  const handleOnOK = () => {
    const { current } = ref;
    const { validateFields } = current;
    validateFields(async (err, values) => {
      const data = {
        OldPassword: encryptAes(values.password),
        NewPassword: encryptAes(values.newPassword)
      };
      if (!err) {
        let newDataUser = { ...dataUser, temporary: false, timelife: true };
        const onSuccess = message => {
          localStorage.setItem('user', JSON.stringify(newDataUser));
          setEqualPass(true);
          sendNotification('success', message);
          setTimeout(() => {
            setShowModal(false);
            history.push('/');
          }, 300);
        };
        const onEqual = () => {
          setNewDate(new Date()); //Funciona para actulizar el componente cada que la peticion es rechazada
          setEqualPass(true);
        };
        changePasswordReq({ dataRequest: data, onSuccess: onSuccess, onEqual: onEqual });
      }
    });
  };

  const handelOnCancel = () => {
    setShowModal(false);
    dispatch(requestLogOut());
  };

  useEffect(() => {
    if (timelife === false && !temporary) {
      setTimeout(() => {
        sendNotification('info', t('MSG_113'));
      }, 300);
    }
  }, [temporary, timelife]);

  useEffect(() => {
    if (temporary) {
      setTimeout(() => {
        sendNotification('info', t('MSG_015'));
      }, 300);
    }
  }, [temporary]);

  // START: Checks if token exists, if not, logout
  useEffect(() => {
    if (!token) {
      dispatch(requestLogOut());
    }
  }, [dispatch, token]);
  // END: Checks if token exists, if not, logout

  return (
    <Layout>
      <TopBar temporaryRedux={userRedux.temporary ?? false} />
      {(temporary === false || timelife === true) && (
        <ContainerSideNavBar toggleSide={toggleSide} drawerIsVisible={drawerIsVis}>
          {children}
        </ContainerSideNavBar>
      )}
      {(temporary === true || timelife === false) && (
        <ContainerSideNavBar toggleSide={toggleSide} drawerIsVisible={drawerIsVis}>
          {children}
        </ContainerSideNavBar>
      )}
      {(temporary === true || timelife === false) && (
        <Modal
          jest-id="ResetPassword"
          isLoading={password.isChanging}
          title={t('myprofile.updatepassword')}
          type="choice"
          className="my-modal-size"
          visible={showModal}
          onOk={handleOnOK}
          closable={false}
          cancelButtonProps={{ onClick: handelOnCancel }}
        >
          <ChangePasswordForm
            ref={ref}
            equalPass={equalPass}
            setEqualPass={setEqualPass}
            newDate={newDate}
          />
        </Modal>
      )}
      <ExpiredSession />
    </Layout>
  );
}

PrivateLayout.propTypes = {
  children: PropTypes.element.isRequired,
  drawerIsVis: PropTypes.bool
};

PrivateLayout.defaultProps = {
  children: <div />,
  drawerIsVis: true
};

function mapStateToProps(state) {
  return {
    password: state.user.password,
    drawerIsVis: state.layout.drawerIsVisible,
    userRedux: state.user.info
  };
}

export default withRouter(
  connect(mapStateToProps, { toggleSideBar, forceLogout, changePasswordRequest })(PrivateLayout)
);
