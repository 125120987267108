import React, { forwardRef, useEffect } from 'react';
import { Form, Input, Icon } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { setRuleRequired, setRuleEmail, setLowerCase, setRuleMax } from 'utils/validations';

const RecoveryForm = forwardRef((props, ref) => {
  const { form, errorMessage, showModal } = props;
  const { Item } = Form;
  const { getFieldValue, setFields, getFieldDecorator, resetFields } = form;

  const { t } = useTranslation();
  useEffect(() => {
    if (errorMessage !== null) {
      const value = getFieldValue('email');
      setFields({
        email: {
          value: value,
          errors: ['']
        }
      });
    }
  }, [errorMessage, getFieldValue, setFields, showModal, resetFields]);

  return (
    <Form ref={ref} style={{ padding: 35 }}>
      <p style={{ marginBottom: '24px' }}>
        {t('loginform.textresetpassword')}
      </p>
      <Item>
        {getFieldDecorator('email', {
          rules: [setRuleEmail('usuario'), setRuleRequired('usuario'), setRuleMax(80)],
          normalize: value => setLowerCase(value)
        })(
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.35)' }} />}
            placeholder={t('loginform.user')}
            size="large"
            maxLength={80}
            autoComplete="none"
            type="email"
            allowClear
          />
        )}
      </Item>
    </Form>
  );
});

RecoveryForm.propTypes = {
  form: PropTypes.object.isRequired
};

RecoveryForm.displayName = 'RecoveryForm';

const RecoveryCreator = Form.create({ name: 'email-in-modal' })(RecoveryForm);

export default RecoveryCreator;
