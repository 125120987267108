/* istanbul ignore file */
import React, { useEffect, useState, useCallback } from 'react';
import { Modal, Form, Button } from 'antd';
import SInput from 'components/common/SInput';
import { connect } from 'react-redux';
import { forceLogout } from 'redux/actions/authActions';
import { useTranslation } from 'react-i18next';

function SessionKillerForm(props) {
  const { form, userRejected, forceLogout: duplicateSession } = props;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const checkIfShow = useCallback(
    e => {
      const { ctrlKey, shiftKey, keyCode } = e;

      //  if (ctrlKey && keyCode === 17) {
      if (ctrlKey && shiftKey && keyCode === 11) {
        setVisible(!visible);
      }
    },
    [visible]
  );

  useEffect(() => {
    window.addEventListener('keypress', checkIfShow);
    return () => {
      window.removeEventListener('keypress', checkIfShow);
    };
  }, [checkIfShow]);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(err => {
      if (!err) {
        duplicateSession({ username: userRejected });
      }
    });
  };

  return visible ? (
    <Modal
      // eslint-disable-next-line jsx-a11y/accessible-emoji
      title={<span>😈 </span>}
      visible
      footer={null}
      onSubmit={handleSubmit}
      onCancel={() => setVisible(false)}
    >
      <Form>
        <SInput form={form} label={t('loginform.user')} initialValue={userRejected} />
        <Button
          onClick={handleSubmit}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Kill this session
        </Button>
      </Form>
    </Modal>
  ) : null;
}
const SessionKiller = Form.create({ name: 'session-killer' })(SessionKillerForm);

function mapStateToProps(state) {
  return {
    userRejected: state.auth.userRejected
  };
}

export default connect(mapStateToProps, { forceLogout })(SessionKiller);
