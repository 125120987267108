import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { HubConnectionBuilder, HttpTransportType, LogLevel } from '@microsoft/signalr';
import {backgroundDownloadSuccess} from '../../../redux/actions/downloadFile'
import { downloadFileFromBase64, downloadReport } from 'services/Queue';
import { sendNotification } from 'helpers/helperNotifications';

const QueueSocket = (props) => {
  const { queue, isAuthenticated } = props;
  const [ connection, setConnection ] = useState(null);
  const reportsSocketUrl = process.env.REACT_APP_REPORT_SOCKET_URL;

  useEffect(() => {
    if(isAuthenticated){
      const token = localStorage.getItem('tkn');
      const newConnection = new HubConnectionBuilder()
      .withUrl(`${reportsSocketUrl}?access_token=${token}`,
      {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        timeout: 300000,
      }
      )
      .withAutomaticReconnect()
      .build();

      newConnection.serverTimeoutInMilliseconds = 300000; // Set the server timeout to 30 seconds  
      newConnection.keepAliveIntervalInMilliseconds = 5000; // Set the ping interval to 10 seconds  

      setConnection(newConnection);
    }else{
      setConnection(null);
    }
    
}, [isAuthenticated]);

  useEffect(() => {
    if (connection && connection.state !== 'Connected' && isAuthenticated) {
      connection.start()
        .then(result => {
          connection.on('ReceiveReport', (advertId, bytes, ext, name, userId, reportType) => {
            downloadReport(advertId, bytes, ext, name, userId, reportType);
          })
          connection.on('ReceiveFile', (base64, ext, name, userId, data) =>{
            downloadFileFromBase64(base64, ext, name)
          });
          connection.on('ReceiveNotification', (message) =>{
            sendNotification('info', message);
          })
          connection.onclose(error => {
            connection.off('ReceiveReport');
            connection.off('ReceiveFile');
            connection.off('ReceiveNotification');
            setTimeout(connect.start, 1000);
          });
        })
        .catch(e => {
          console.log('Connection failed: ', e);
          setTimeout(connect.start, 1000);
         });
    } else {
      if (!isAuthenticated && connection && connection.state == 'Connected') {
        connection.off('ReceiveReport');
        connection.off('ReceiveFile');
        connection.off('ReceiveNotification');
        connection.stop().then(result => { });
      }
    }
  }, [connection, connection?.state, isAuthenticated]);

  return (
    <></>
  )
}
function mapStateToProps(state) {
  return {
    queue: state.downloadFile.queue
  };
}

export default connect(mapStateToProps, {
  backgroundDownloadSuccess
})(QueueSocket);