import React from 'react';
import { connect } from 'react-redux';
import Routes from '../../routes/';
import PropTypes from 'prop-types';

import QueueSocket from '../Sockets/QueueSocket/QueueSocket';
import FusionCharts from "fusioncharts";



FusionCharts.options.license({
  key:`${process.env.REACT_APP_KEY_FUSION}`,
  creditLabel: false,
});

function App(props) {
  const { isAuthenticated} = props;

  return <>
    <Routes isAuthenticated={isAuthenticated} />
    <QueueSocket isAuthenticated={isAuthenticated}/>
  </>;
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export default connect(mapStateToProps, {})(App);
App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

App.defaultProps = {
  isAuthenticated: false
};
