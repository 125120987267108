import React from 'react';

import UserNotAllowed from '../common/UserNotAllowed';
import PropTypes from 'prop-types';

function AccessControlPages({ child, extraAccessValidation, hasAccessTo, menuAllowed, user }) {
  const RenderComponet = () => child;
  let isAllowed = false;

  if (!user.isAuthenticated || !menuAllowed) {
    return <RenderComponet />;
  }
  if (menuAllowed && menuAllowed.length > 0) {
    let page = hasAccessTo.toLowerCase();

    let isComplete = page.endsWith('/');
    isAllowed = menuAllowed.includes(isComplete ? page : `${page}/`);
  }

  if (extraAccessValidation && extraAccessValidation()) {
    isAllowed = extraAccessValidation();
  }
  //comentar este segmento ,checar que todos los perfiles tenga agregadas las página a las cuál tienen acceso
  if (menuAllowed && menuAllowed.length > 0) {
    return <RenderComponet />;
  }
  if (!isAllowed) {
    return <UserNotAllowed />;
  }
  return <RenderComponet />;
}
/**it was connected with state as meniAllowed  on user.menu*/

export default AccessControlPages;

AccessControlPages.propTypes = {
  hasAccessTo: PropTypes.string,
  child: PropTypes.object,
  extraAccessValidation: PropTypes.func
};

AccessControlPages.defaultProps = {
  hasAccessTo: '/',
  user: false
};
